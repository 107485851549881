export function useCurrentPath() {
  const route = useRoute()
  const getRouteBaseName = useRouteBaseName()

  const currentPath = computed(() => getRouteBaseName(route))
  const localePath = useLocalePath()

  const isCurrentPath = (path: string) => {
    if (!currentPath.value) { return false }
    return localePath(currentPath.value) === localePath(path)
  }

  return { currentPath, isCurrentPath }
}
